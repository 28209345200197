import * as investmentsRequestsActions from '../../actions/investmentsRequestsActions/constants'

const initialState = {
    investmentsRequests: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case investmentsRequestsActions.GET_INVESTMENTS_REQUESTS_REQUEST:
            return {
                ...state,
            };
        case investmentsRequestsActions.GET_INVESTMENTS_REQUESTS_SUCCESS:
            return {
                ...state,
                investmentsRequests: action.payload,
            };
        case investmentsRequestsActions.GET_INVESTMENTS_REQUESTS_FAILURE:
            return {
                ...state,
            };
        // case administratorsActions.GET_ADMINISTRATORS_ROLES_REQUEST:
        //     return {
        //         ...state,
        //     };
        // case administratorsActions.GET_ADMINISTRATORS_ROLES_SUCCESS:
        //     return {
        //         ...state,
        //         roles: action.payload,
        //     };
        // case administratorsActions.GET_ADMINISTRATORS_ROLES_FAILURE:
        //     return {
        //         ...state,
        //     };
        default:
            return state
    }
}