import React from 'react';
import {URLS_LOCAL_GROUPS} from "./urls";

const ForgotPassword = React.lazy(() => import('./App/components/Authentication/ForgotPassword'));
const SignIn = React.lazy(() => import('./App/components/Authentication/SignIn'));
const SetNewPassword = React.lazy(() => import('./App/components/Authentication/SetNewPassword'));
const Setup2FA = React.lazy(() => import('./App/components/Authentication/2fa/setup-2fa'));
const VerifyOTP = React.lazy(() => import('./App/components/Authentication/2fa/verify-otp'));

const authRoutes = [
    { path: URLS_LOCAL_GROUPS.forgotPassword, exact: true, name: 'Reset Password', component: ForgotPassword },
    { path: URLS_LOCAL_GROUPS.signIn, exact: true, name: 'Sign In', component: SignIn },
    { path: URLS_LOCAL_GROUPS.setup2fa, exact: true, name: 'Setup 2FA', component: Setup2FA },
    { path: URLS_LOCAL_GROUPS.verifyOTP, exact: true, name: 'Verify OTP', component: VerifyOTP },
    { path: URLS_LOCAL_GROUPS.passwordReset, exact: true, name: 'Set New Password', component: SetNewPassword },
];

export default authRoutes;