export const URL_PROTOCOL = process.env.NODE_HTTP_PROTOCOL;
export const URL_HOST = process.env.NODE_HTTP_HOST;
export const URL_PORT = +process.env.NODE_HTTP_PORT;
export const URL_FULL = URL_HOST;

export const URLS_LOCAL_GROUPS = {
    signIn: '/sign-in',
    setup2fa: '/setup-2fa',
    verifyOTP: '/verify-otp/:stage',
    verifyOTPSignIn: '/verify-otp/login',
    verifyOTPSetup: '/verify-otp/setup',
    signUp: '/sign-up',
    forgotPassword: '/forgot-password',
    // reports: '/reports',
    security: '/security',
    passwordReset: '/password-reset/:uid/:token',
    users: {
        clients: '/users/clients',
        administrators: '/users/administrators'
    },
    bonuses: {
        list: '/bonuses/list',
        create: '/bonuses/create',
        transactions: '/bonuses/transactions'

    },
    coupons: {
        list: '/coupons/list',
        create: '/coupons/create',
        transactions: '/coupons/transactions'
    },
    airdrop: {
        list: '/airdrop/list',
        payments: '/airdrop/orders',
        hashes: id => `/airdrop/${id}/hashes`,
        orders: (id, title) => `/airdrop/${id}/${title}/orders/`,
    },
    promo_codes: {
        promo_codes: '/promo-codes',
        promo_codes_list: '/promo-codes/list',
        promo_codes_create: '/promo-codes/create',
        promo_codes_groups: '/promo-codes/groups',
        promo_codes_groups_create: '/promo-codes/groups/create',
        promo_codes_groups_edit: '/promo-codes/groups/edit/:id',
        transactions: '/promo-codes/transactions',
    },
    staking: {
        products: {
            products: '/staking/products',
            create: '/staking/products/create-staking-product',
            edit: '/staking/products/edit-staking-product',
            product: '/staking/products/:id',
        },
        transactions: {
            transactions: '/staking/Transactions'
        },
        deposits: {
            deposits: '/staking/deposits',
            deposits_requests: '/staking/deposit-requests'
        },
        dashboard: '/staking/staking-dashboard',
        wallets: '/staking/wallets'
    },
    investmentsRequests: '/investments-requests',
    invoices: {
        invoices: '/invoices',
        create: '/invoices/create-new-invoice',
        update: '/invoices/update-invoice',
    },
    payments: {
        payments: '/payments',
        addPayment: '/payments/add-payment'
    },
    affiliates: {
        list: '/affiliates',
    },
    referrals: {
        referrals: '/referrals',
    },
    reports: {
        transactions: '/reports/transactions',
        registrations: '/reports/registrations',
        investments: '/reports/investments',
    },
    settings: {
        stages: '/settings/stages',
        app: '/settings/app'
    },
    blog: {
        blog: '/blog',
    },
};

export const URLS_LOCAL_USER = {
    activity: '/activity',
    statistics: '/statistics',
    profile: '/profile',
    security: '/security',
    bankDetails: '/bank-details',
    personalInfo: '/personal-info',
    investments: '/investments',
    documents: '/documents',
    products: '/products',
    administrators: '/users/administrators',
    clients: '/users/clients',
    dashboard: '/dashboard',
    withdrawals: '/withdrawals',
    createInvestment: '/create-new-investment',
    comments: '/comments',
    transactions: '/transactions',
    referrals: '/referrals'
};

export const API_URLS = {
    options: {
        stakingOptions: '/admin/constants',
        userOptions: '/admin/constants',
        productOptions: '/api/products/product-const/',
        transactionsOptions: '/api/payments/options/',
        invoicesOptions: '/api/invoices/invoice-const/',
        currencies: '/admin/currencies',
        airdropOptions: '/admin/airdrop/const',
    },
    affiliates: {
        list: '/admin/affilate-list',
        reports: '/admin/affilate-list/csv'
    },
    referrals: {
        refList: '/admin/ref-list'
    },
    blog: {
        posts: '/blog',
        addPost: '/admin/blog/add',
        editPost: '/admin/blog/edit'
    },
    payments: {
        updateStatistic: '/admin/payment/get-payment-status-statistic',
        bulkUpdate: '/admin/payment/bulk-update-status',
        getRates: '/admin/payment/get-rates',
        getPayments: '/admin/payment/list',
        createPayment: '/api/transactions/add-transaction/',
        updateStatus: id => `/admin/payment/update-status/${id}`,
        updateTrx: (id, type) => `/admin/payment/${type}/${id}`,
        addBonus: '/admin/payment/add-bonuses-manually',
    },
    airdrop: {
        list: '/admin/airdrop',
        create: '/admin/airdrop/create',
        updateOrder: `/admin/airdrop/orders/update`,
        get_by_id: id => `/admin/airdrop/orders-by-airdrop/${id}`,
        download_csv: id => `/admin/airdrop/orders-by-airdrop/csv/${id}`,
        update_by_id: `/admin/airdrop/update`,
        stages: '/admin/airdrop/form',
        orders: '/admin/airdrop/orders',
        add_hash: '/admin/airdrop/add-hash',
        match_trx: '/admin/airdrop/check-airdrop',
    },
    bonuses: {
        getBonusesList: '/admin/bonus',
        getBonus: id => `/admin/bonus/${id}`,
        bonusesTransactions: id => id ? `/admin/bonus/payments/${id}` : '/admin/bonus/payments',
        updateBonus: `/admin/bonus/update`,
        createBonus: `/admin/bonus/create`,
        deleteBonusTransaction: `/admin/payment/del-bonuses-manually`,
    },
    coupons: {
        getCouponsList: '/admin/coupon',
        getCoupon: id => `/admin/coupon/${id}`,
        couponTransactions: id => id ? `/admin/coupon/payments/${id}` : '/admin/coupon/payments',
        updateCoupon: `/admin/coupon/update`,
        createCoupon: `/admin/coupon/create`,
    },
    dashboard: {
        raisedDataFeed: '/admin/payment/usd-chart',
        tokensSoldDataFeed: '/admin/payment/token-chart',
        priceDataFeed: '/admin/payment/price-chart',
        dashboard: '/admin/dashboard',
        byCountries: '/admin/countries'
    },
    reports: {
        reports: '/api/reports/',
        download: '/api/reports/xlsx-user-report/'
    },
    signIn: '/sign-in/admin',
    signOut: '/sign-out/admin',
    getQr: '/api/user/administrate/get-qr/',
    signUp: '/api/sign-up',
    verifyOTP: '/api/user/administrate/verify-otp/',
    signIn2FA: '/api/user/sign-in-2fa/',
    infoUser: '/api/info',
    products: '/api/products/',
    addProduct: '/api/products/product-add/',
    updateProduct: '/api/products/edit/',
    changeProductStatus: '/api/products/product-change-status/',
    investments: '/api/requests/request-list/',
    cancelRequest: '/api/requests/cancel-request/',
    refreshToken: '/api/refresh-token',
    resetPassword: '/api/users/administrate/send-password/',
    sendResetEmailLink: '/forgot-password/admin',
    changeAdminRoleOrStatus: '/admin/change-role',
    changeAdminStatus: '/admin/change-status',
    adminResetPassword: '/reset-password/admin',
    deleteAdminUser: '/admin/delete',
    investmentOptions: '/api/options/investments',
    passwordReset: '/reset-password/admin',
    settings: {
        stages: {
            stages: '/admin/stage',
            create: '/admin/stage/create',
            edit: '/admin/stage/edit',
            open_close: '/admin/stage/close',
            delete: '/admin/stage/delete',
        },
        app: '/admin/settings'
    },
    staking: {
        wallets: {
            wallets: '/admin/staking/wallets',
            changeStatus: id => `/admin/staking/wallets/${id}`,
        },
        products: {
            getProducts: '/admin/staking',
            getProduct: id => `/admin/staking/${id}`,
            createProduct: '/admin/staking/create',
            updateProduct: id => `/admin/staking/update`,
            change_status: `/admin/staking/change-status`,
        },
        deposits: {
            deposits: '/admin/staking/deposits',
            requests: '/admin/staking/deposit-requests',
            deposit: id => `/admin/staking/deposits/${id}`,
        },
        transactions: {
            transactions: '/admin/staking/payments'
        },
        dashboard: {
            dashboard: '/admin/staking/dashboard',
            stakingChart: '/admin/staking/staking-chart ',
            byProgramChart: '/admin/staking/program-stat',
        }
    },
    clients: {
        clients: '/admin/clients',
        changeClientStatus: '/admin/client-status/change',
        sendConfirmationEmailLink: '/admin/send-confirmation',
        assignSales: '/admin/bulk-assign-to-sale',
        client: {
            header: '/api/user/info/header/',
            profile: '/admin/client-info/',
            updateProfile: '/admin/client-info/edit',
            documents: '/api/user/documents-list/',
            setDocumentsStatus: '/api/user/approve-documents/',
            updateDocuments: '/api/user/documents-list/create/',
            investments: '/api/investments/client-investments/',
            transactions: '/api/transactions/list/',
            statistics: '/api/user/clients/statistic/',
            createWithdrawal: '/api/transactions/withdrawals/',
            createInvestmentRequest: '/api/investments/create-investment/',
            kickInvestment: '/api/investments/kick-investment/',
            getBankDetails: '/api/user/bank-details/',
            updateBankDetails: '/api/user/admin-edit-bank-details/',
            getComments: '/api/user/clients/comments/',
            getSales: '/api/user/sales-users/',
            referrals: '/admin/ref-list/',
            comments: id => `/admin/notes/${id}`,
            addComment: '/admin/notes/add',
            editComment: '/admin/notes/edit',
            addReferrer: '/admin/payment/add-ref-manually',
        }
    },
    clientProfile: '/profile',
    adminsList: '/admin/admins',
    promo_codes: {
        codes_list: '/admin/promo-code',
        codes_groups: '/admin/promo-code/conditions',
        add_group: '/admin/promo-code/conditions/create',
        add_code: '/admin/promo-code/create',
        cancel_code: '/admin/promo-code/edit',
        close_group: '/admin/promo-code/conditions/edit',
        trx: '/admin/promo-code/payments'
    },
    adminEditUser: '/api/user/administrate/edit/',
    adminInviteUser: '/admin/invite',
};

export const buildAPIUrl = url => {
    if (process.env.NODE_ENV === "development") {
        return url
    } else {
        return process.env.REACT_APP_NODE_HTTP_HOST + url
    }
};