import * as userActions from './constants.js'
import {handleRespErr} from '../../../helpers/respErrorsHandler'
import {toast} from "react-toastify";
import {GetAxios} from '../../../helpers/axios';
import {API_URLS, buildAPIUrl, URLS_LOCAL_GROUPS} from "../../../urls";
import axios from "axios";
import {setLoginCookies, deleteLoginCookies} from '../../../helpers/cookies'

export function userSignInAction(email, password, router) {

    return dispatch => {

        dispatch(userSignInActionRequest());

        const userData = {
            email: email,
            password: password
        };

        axios.defaults.withCredentials = true

        axios.post(buildAPIUrl(API_URLS.signIn), userData, {
            headers: {
                'Content-Type': 'application/json',
                'Front-Host': 'admin'
            }
        })
            .then((res) => {

                const parseJwt = (token) => {
                    try {
                        return JSON.parse(atob(token.split('.')[1]));
                    } catch (e) {
                        return null;
                    }
                };

                const {role} = parseJwt(res.data.access_token)

                let cookies = {
                    loggedIn: {
                        value: true,
                        options: {}
                    },
                    role: {
                        value: role,
                        options: {}
                    },
                    accessToken: {
                        value: res.data.access_token,
                        options: {
                            'Domain': process.env.NODE_ENV === 'development' ? 'localhost' : process.env.REACT_APP_NODE_DOMAIN
                        }
                    },
                    user_id: {
                        value: res.data.public_id,
                        options: {
                            'Domain': process.env.NODE_ENV === 'development' ? 'localhost' : process.env.REACT_APP_NODE_DOMAIN
                        }
                    },
                    user_name: {
                        value: res.data.user_name?.first_name + ' ' + res.data.user_name?.last_name,
                        options: {
                            'Domain': process.env.NODE_ENV === 'development' ? 'localhost' : process.env.REACT_APP_NODE_DOMAIN
                        }
                    },
                    user_public_id: {
                        value: res.data.public_id,
                        options: {
                            'Domain': process.env.NODE_ENV === 'development' ? 'localhost' : process.env.REACT_APP_NODE_DOMAIN
                        }
                    },
                }
                setLoginCookies(cookies);
                dispatch(userSignInActionSuccess({...res.data, role: role}));
            })
            .catch(handleRespErr(dispatch, toast.error, userSignInActionFailure))
    }
}

function userSignInActionRequest() {
    return {
        type: userActions.USER_SIGN_IN_REQUEST,
    }
}

function userSignInActionSuccess(userData) {
    return {
        type: userActions.USER_SIGN_IN_SUCCESS,
        payload: userData
    }
}

function userSignInActionFailure(error) {
    return {
        type: userActions.USER_SIGN_IN_FAILURE,
        error: error
    }
}

export function userSignOutAction() {

    return dispatch => {

        dispatch(userSignOutRequest());

        let axios = GetAxios(dispatch)

        let headers = {
                'Content-Type': 'application/json',
            }

        axios.put(buildAPIUrl(API_URLS.signOut), {}, {
            headers: headers
        })
            .then(res => {
                deleteLoginCookies();
                dispatch(userSignOutSuccess(res.data));
            })
            .catch(handleRespErr(dispatch, toast.error, userSignOutFailure))
    }
}

export function userSignOutRequest() {
    return {
        type: userActions.USER_SIGN_OUT_REQUEST
    }
}

export function userSignOutSuccess() {
    return {
        type: userActions.USER_SIGN_OUT_SUCCESS
    }
}

export function userSignOutFailure() {
    return {
        type: userActions.USER_SIGN_OUT_FAILURE
    }
}

export function resetPassword(params, cb) {

    return dispatch => {
        let axios = GetAxios(dispatch);

        dispatch(resetPasswordRequest());

        axios.get(buildAPIUrl(API_URLS.resetPassword), {
            params,
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                toast.success(params.hasOwnProperty('user_id') ? 'Reset link has been sent to user email' : 'Check your email');
                dispatch(resetPasswordSuccess(res.data));
                cb()
            })
            .catch(handleRespErr(dispatch, toast.error, resetPasswordFailure))
    }
}

export function resetPasswordRequest() {
    return {
        type: userActions.USER_SEND_RESET_EMAIL_REQUEST
    }
}

export function resetPasswordSuccess() {
    return {
        type: userActions.USER_SEND_RESET_EMAIL_SUCCESS
    }
}

export function resetPasswordFailure() {
    return {
        type: userActions.USER_SEND_RESET_EMAIL_FAILURE
    }
}

export function verifyOTP(data, stage) {

    return dispatch => {
        let axios = GetAxios(dispatch);
        dispatch(verifyOTPRequest());

        let url = stage === 'setup' ? API_URLS.verifyOTP : API_URLS.signIn2FA;

        axios.post(buildAPIUrl(url), data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                dispatch(verifyOTPSuccess(res.data));
                dispatch(userSignInActionSuccess(res.data));
            })
            .catch(handleRespErr(dispatch, toast.error, verifyOTPFailure))
    }
}

export function verifyOTPRequest() {
    return {
        type: userActions.USER_VERIFY_OTP_REQUEST,
    }
}

export function verifyOTPSuccess() {
    return {
        type: userActions.USER_VERIFY_OTP_SUCCESS,
    }
}

export function verifyOTPFailure() {
    return {
        type: userActions.USER_VERIFY_OTP_FAILURE,
    }
}

export function updateAuthToken(token) {
    return {
        type: userActions.USER_UPDATE_TOKEN,
        payload: token
    }
}

export function refreshAuthTokens(tokens) {
    return {
        type: userActions.USER_REFRESH_TOKENS,
        payload: tokens
    }
}

export function onSetNewPassword(password, router) {
    return dispatch => {

        dispatch(onSetNewPasswordRequest());

        axios.defaults.withCredentials = true

        axios.post(buildAPIUrl(API_URLS.passwordReset), password, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                dispatch(onSetNewPasswordSuccess());
                toast.success(res.data.message || 'Password has been changed');
                router.push(URLS_LOCAL_GROUPS.signIn)
            })
            .catch(handleRespErr(dispatch, toast.error, onSetNewPasswordFailure))
    }
}

function onSetNewPasswordRequest() {
    return {
        type: userActions.USER_SET_NEW_PASSWORD_REQUEST
    }
}

function onSetNewPasswordSuccess() {
    return {
        type: userActions.USER_SET_NEW_PASSWORD_SUCCESS
    }
}

function onSetNewPasswordFailure() {
    return {
        type: userActions.USER_SET_NEW_PASSWORD_FAILURE
    }
}


export function userSendResetEmailAction(email, cb) {
    return dispatch => {

        dispatch(userSendResetEmailActionRequest())

        axios.post(buildAPIUrl(API_URLS.sendResetEmailLink), email, {
            headers: {
                'Content-Type': 'application/json',
                'Front-Host': 'admin'
            }
        })
            .then(res => {
                dispatch(userSendResetEmailActionSuccess());
                cb();
                toast.success('A link to reset password has been sent to your email.');
            })
            .catch(handleRespErr(dispatch, toast.error, userSendResetEmailActionFailure))
    }
}

function userSendResetEmailActionRequest() {
    return {
        type: userActions.USER_SEND_RESET_EMAIL_REQUEST,
    }
}

function userSendResetEmailActionSuccess(message) {
    return {
        type: userActions.USER_SEND_RESET_EMAIL_SUCCESS,
    }
}

function userSendResetEmailActionFailure() {
    return {
        type: userActions.USER_SEND_RESET_EMAIL_FAILURE,
    }
}
