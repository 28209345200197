import * as couponsActions from '../../actions/couponsActions/constants'

const initialState = {
    couponsList: null,
    couponTransactions: null,
    coupon: null,
    isLoading: true,
    isTrxLoading: true,
    isCouponEditing: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case couponsActions.GET_COUPONS_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case couponsActions.GET_COUPONS_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                couponsList: action.payload,
            };
        case couponsActions.GET_COUPONS_LIST_FAILURE:
            return {
                ...state,
                couponsList: null,
                isLoading: false,
            };
        case couponsActions.GET_COUPONS_TRANSACTIONS_LIST_REQUEST:
            return {
                ...state,
                isTrxLoading: true,
            };
        case couponsActions.GET_COUPONS_TRANSACTIONS_LIST_SUCCESS:
            return {
                ...state,
                isTrxLoading: false,
                couponTransactions: action.payload,
            };
        case couponsActions.GET_COUPONS_TRANSACTIONS_LIST_FAILURE:
            return {
                ...state,
                couponTransactions: null,
                isTrxLoading: false,
            };
        case couponsActions.GET_COUPON_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case couponsActions.GET_COUPON_SUCCESS:
            return {
                ...state,
                isLoading: false,
                coupon: action.payload,
            };
        case couponsActions.GET_COUPON_FAILURE:
            return {
                ...state,
                coupon: null,
                isLoading: false,
            };
        case couponsActions.UPDATE_COUPON_REQUEST:
            return {
                ...state,
                isCouponEditing: true,
            };
        case couponsActions.UPDATE_COUPON_SUCCESS:
            return {
                ...state,
                isCouponEditing: false,
                coupon: action.payload,
            };
        case couponsActions.UPDATE_COUPON_FAILURE:
            return {
                ...state,
                isCouponEditing: false,
            };
        case couponsActions.CREATE_COUPON_REQUEST:
            return {
                ...state,
                isCouponEditing: true,
            };
        case couponsActions.CREATE_COUPON_SUCCESS:
            return {
                ...state,
                isCouponEditing: false
            };
        case couponsActions.CREATE_COUPON_FAILURE:
            return {
                ...state,
                isCouponEditing: false,
            };
        case couponsActions.CLEAR_COUPON:
            return {
                ...state,
                isCouponEditing: false,
                coupon: null
            };
        default:
            return state
    }
}