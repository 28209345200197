import * as blogActions from '../../actions/blogActions/constants'

const initialState = {
    posts: null,
    isLoading: true,
    processedPost: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case blogActions.GET_POSTS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case blogActions.GET_POSTS_SUCCESS:
            return {
                ...state,
                posts: action.payload,
                isLoading: false
            };
        case blogActions.GET_POSTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                posts: null
            };
        case blogActions.ADD_POST_REQUEST:
            return {
                ...state,
                processedPost: true
            };
        case blogActions.ADD_POST_SUCCESS:
            return {
                ...state,
                processedPost: false
            };
        case blogActions.ADD_POST_FAILURE:
            return {
                ...state,
                processedPost: null
            };
        case blogActions.EDIT_POST_REQUEST:
            return {
                ...state,
                processedPost: true
            };
        case blogActions.EDIT_POST_SUCCESS:
            return {
                ...state,
                processedPost: false
            };
        case blogActions.EDIT_POST_FAILURE:
            return {
                ...state,
                processedPost: null
            };
        default:
            return state
    }
}