import {displayRequestErrors} from './displayRequestErrors'

export function handleRespErr(dispatch, toaster, action) {

    return (res) => {
        const {msg} = responseErrorMsg(res);
        dispatch(action(msg));
        toaster(msg)
    }
}

function responseErrorMsg(res) {

    if (res.response) {
        return {
            msg: res.response.data && res.response.data.error || res.response.data.message || res.message || 'Something went wrong',
        }
    } else {
        return {
            msg: 'Network error'
        }
    }
}

export function handleMatchTrxRespErr(dispatch, toaster, action) {

    return (res) => {
        const {msg} = responseMatchTrxErrorMsg(res);
        dispatch(action(msg));
        toaster(msg)
    }
}


function responseMatchTrxErrorMsg(res) {

    if (res.response) {
        return {
            msg: res?.response?.data?.errors?.error?.message || 'Something went wrong',
        }
    } else {
        return {
            msg: 'Network error'
        }
    }
}