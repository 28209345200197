export const GET_STAGES_REQUEST = 'GET_STAGES_REQUEST';
export const GET_STAGES_SUCCESS = 'GET_STAGES_SUCCESS';
export const GET_STAGES_FAILURE = 'GET_STAGES_FAILURE';

export const CREATE_STAGE_REQUEST = 'CREATE_STAGE_REQUEST';
export const CREATE_STAGE_SUCCESS = 'CREATE_STAGE_SUCCESS';
export const CREATE_STAGE_FAILURE = 'CREATE_STAGE_FAILURE';

export const UPDATE_STAGE_REQUEST = 'UPDATE_STAGE_REQUEST';
export const UPDATE_STAGE_SUCCESS = 'UPDATE_STAGE_SUCCESS';
export const UPDATE_STAGE_FAILURE = 'UPDATE_STAGE_FAILURE';

export const OPEN_CLOSE_STAGE_REQUEST = 'OPEN_CLOSE_STAGE_REQUEST';
export const OPEN_CLOSE_STAGE_SUCCESS = 'OPEN_CLOSE_STAGE_SUCCESS';
export const OPEN_CLOSE_STAGE_FAILURE = 'OPEN_CLOSE_STAGE_FAILURE';

export const DELETE_STAGE_REQUEST = 'DELETE_STAGE_REQUEST';
export const DELETE_STAGE_SUCCESS = 'DELETE_STAGE_SUCCESS';
export const DELETE_STAGE_FAILURE = 'DELETE_STAGE_FAILURE';

export const GET_APP_SETTINGS_REQUEST = 'GET_APP_SETTINGS_REQUEST';
export const GET_APP_SETTINGS_SUCCESS = 'GET_APP_SETTINGS_SUCCESS';
export const GET_APP_SETTINGS_FAILURE = 'GET_APP_SETTINGS_FAILURE';

export const UPDATE_APP_SETTINGS_REQUEST = 'UPDATE_APP_SETTINGS_REQUEST';
export const UPDATE_APP_SETTINGS_SUCCESS = 'UPDATE_APP_SETTINGS_SUCCESS';
export const UPDATE_APP_SETTINGS_FAILURE = 'UPDATE_APP_SETTINGS_FAILURE';
