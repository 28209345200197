import * as settingsActions from '../../actions/settingsActions/constants'

const initialState = {
    stages: null,
    isLoadingStages: true,
    isProceedStage: false,
    appSettings: null,
    isProceedAppSettings: false,
    isLoadingAppSettings: true,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case settingsActions.GET_STAGES_REQUEST:
            return {
                ...state,
                isLoadingStages: true
            };
        case settingsActions.GET_STAGES_SUCCESS:
            return {
                ...state,
                stages: action.payload,
                isLoadingStages: false
            };
        case settingsActions.GET_STAGES_FAILURE:
            return {
                ...state,
                stages: null,
                isLoadingStages: false
            };
        case settingsActions.CREATE_STAGE_REQUEST:
            return {
                ...state,
                isProceedStage: true
            };
        case settingsActions.CREATE_STAGE_SUCCESS:
            return {
                ...state,
                isProceedStage: false
            };
        case settingsActions.CREATE_STAGE_FAILURE:
            return {
                ...state,
                isProceedStage: false
            };
        case settingsActions.UPDATE_STAGE_REQUEST:
            return {
                ...state,
                isProceedStage: true
            };
        case settingsActions.UPDATE_STAGE_SUCCESS:
            return {
                ...state,
                isProceedStage: false
            };
        case settingsActions.UPDATE_STAGE_FAILURE:
            return {
                ...state,
                isProceedStage: false
            };
        case settingsActions.OPEN_CLOSE_STAGE_REQUEST:
            return {
                ...state,
                isProceedStage: true
            };
        case settingsActions.OPEN_CLOSE_STAGE_SUCCESS:
            return {
                ...state,
                isProceedStage: false
            };
        case settingsActions.OPEN_CLOSE_STAGE_FAILURE:
            return {
                ...state,
                isProceedStage: false
            };
        case settingsActions.DELETE_STAGE_REQUEST:
            return {
                ...state,
                isProceedStage: true
            };
        case settingsActions.DELETE_STAGE_SUCCESS:
            return {
                ...state,
                isProceedStage: false
            };
        case settingsActions.DELETE_STAGE_FAILURE:
            return {
                ...state,
                isProceedStage: false
            };
        case settingsActions.GET_APP_SETTINGS_REQUEST:
            return {
                ...state,
                isLoadingAppSettings: true,
            };
        case settingsActions.GET_APP_SETTINGS_SUCCESS:
            return {
                ...state,
                appSettings: action.payload,
                isLoadingAppSettings: false
            };
        case settingsActions.GET_APP_SETTINGS_FAILURE:
            return {
                ...state,
                appSettings: null,
                isLoadingAppSettings: false
            };
        case settingsActions.UPDATE_APP_SETTINGS_REQUEST:
            return {
                ...state,
                isProceedAppSettings: true
            };
        case settingsActions.UPDATE_APP_SETTINGS_SUCCESS:
            return {
                ...state,
                isProceedAppSettings: false
            };
        case settingsActions.UPDATE_APP_SETTINGS_FAILURE:
            return {
                ...state,
                isProceedAppSettings: false
            };
        default:
            return state
    }
}