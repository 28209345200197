import * as referralsActions from '../../actions/referralsActions/constants'

const initialState = {
    referrals: null,
    isLoading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case referralsActions.GET_REFERRALS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case referralsActions.GET_REFERRALS_SUCCESS:
            return {
                ...state,
                referrals: action.payload,
                isLoading: false
            };
        case referralsActions.GET_REFERRALS_FAILURE:
            return {
                ...state,
                isLoading: false,
                referrals: null
            };
        default:
            return state
    }
}