export const GET_OPTIONS_REQUEST = 'GET_OPTIONS_REQUEST';
export const GET_OPTIONS_SUCCESS = 'GET_OPTIONS_SUCCESS';
export const GET_OPTIONS_FAILURE = 'GET_OPTIONS_FAILURE';

export const GET_PRODUCT_OPTIONS_REQUEST = 'GET_PRODUCT_OPTIONS_REQUEST';
export const GET_PRODUCT_OPTIONS_SUCCESS = 'GET_PRODUCT_OPTIONS_SUCCESS';
export const GET_PRODUCT_OPTIONS_FAILURE = 'GET_PRODUCT_OPTIONS_FAILURE';

export const GET_TRANSACTIONS_OPTIONS_REQUEST = 'GET_TRANSACTIONS_OPTIONS_REQUEST';
export const GET_TRANSACTIONS_OPTIONS_SUCCESS = 'GET_TRANSACTIONS_OPTIONS_SUCCESS';
export const GET_TRANSACTIONS_OPTIONS_FAILURE = 'GET_TRANSACTIONS_OPTIONS_FAILURE';

export const GET_INVOICES_OPTIONS_REQUEST = 'GET_INVOICES_OPTIONS_REQUEST';
export const GET_INVOICES_OPTIONS_SUCCESS = 'GET_INVOICES_OPTIONS_SUCCESS';
export const GET_INVOICES_OPTIONS_FAILURE = 'GET_INVOICES_OPTIONS_FAILURE';

export const GET_CURRENCIES_REQUEST = 'GET_CURRENCIES_REQUEST';
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
export const GET_CURRENCIES_FAILURE = 'GET_CURRENCIES_FAILURE';

export const GET_AIRDROP_OPTIONS_REQUEST = 'GET_AIRDROP_OPTIONS_REQUEST';
export const GET_AIRDROP_OPTIONS_SUCCESS = 'GET_AIRDROP_OPTIONS_SUCCESS';
export const GET_AIRDROP_OPTIONS_FAILURE = 'GET_AIRDROP_OPTIONS_FAILURE';
