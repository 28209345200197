import * as paymentsActions from '../../actions/paymentsActions/constants'

const initialState = {
    rates: null,
    isLoadRates: false,
    payments: null,
    invoices: null,
    currentInvoice: null,
    isLoading: true,
    isPaymentEditing: false,
    isLoadingStatistic: true,
    statistic: null,
    isBulkUpdating: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case paymentsActions.GET_PAYMENTS_STATISTIC_REQUEST:
            return {
                ...state,
                isLoadingStatistic: true,
            };
        case paymentsActions.GET_PAYMENTS_STATISTIC_SUCCESS:
            return {
                ...state,
                statistic: action.payload,
                isLoadingStatistic: false,
            };
        case paymentsActions.GET_PAYMENTS_STATISTIC_FAILURE:
            return {
                ...state,
                statistic: null,
                isLoadingStatistic: false,
            };
        case paymentsActions.BULK_PAYMENTS_UPDATE_REQUEST:
            return {
                ...state,
                isBulkUpdating: true,
            };
        case paymentsActions.BULK_PAYMENTS_UPDATE_SUCCESS:
            return {
                ...state,
                isBulkUpdating: false,
            };
        case paymentsActions.BULK_PAYMENTS_UPDATE_FAILURE:
            return {
                ...state,
                isBulkUpdating: false,
            };
        case paymentsActions.GET_RATES_REQUEST:
            return {
                ...state,
                isLoadRates: true,
            };
        case paymentsActions.GET_RATES_SUCCESS:
            return {
                ...state,
                isLoadRates: false,
                rates: action.payload,
            };
        case paymentsActions.GET_RATES_FAILURE:
            return {
                ...state,
                rates: null,
                isLoadRates: false,
            };
        case paymentsActions.GET_PAYMENTS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case paymentsActions.GET_PAYMENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                payments: action.payload,
            };
        case paymentsActions.GET_PAYMENTS_FAILURE:
            return {
                ...state,
                payments: null,
                isLoading: false,
            };
        case paymentsActions.UPDATE_PAYMENT_DATA_REQUEST:
            return {
                ...state,
                isPaymentEditing: true,
            };
        case paymentsActions.UPDATE_PAYMENT_DATA_SUCCESS:
            return {
                ...state,
                isPaymentEditing: false,
            };
        case paymentsActions.UPDATE_PAYMENT_DATA_FAILURE:
            return {
                ...state,
                isPaymentEditing: false,
            };
        case paymentsActions.GET_INVOICES_TO_CREATE_PAYMENT_REQUEST:
            return {
                ...state,
            };
        case paymentsActions.GET_INVOICES_TO_CREATE_PAYMENT_SUCCESS:
            return {
                ...state,
                invoices: action.payload,
            };
        case paymentsActions.GET_INVOICES_TO_CREATE_PAYMENT_FAILURE:
            return {
                ...state,
            };
        case paymentsActions.ERASE_INVOICES:
            return {
                ...state,
                invoices: null
            };
        case paymentsActions.GET_CURRENT_INVOICE_FOR_CREATE_PAYMENT_SUCCESS:
            return {
                ...state,
                currentInvoice: action.payload,
            };
        default:
            return state
    }
}