export const GET_COUPONS_LIST_REQUEST = 'GET_COUPONS_LIST_REQUEST';
export const GET_COUPONS_LIST_SUCCESS = 'GET_COUPONS_LIST_SUCCESS';
export const GET_COUPONS_LIST_FAILURE = 'GET_COUPONS_LIST_FAILURE';

export const GET_COUPONS_TRANSACTIONS_LIST_REQUEST = 'GET_COUPONS_TRANSACTIONS_LIST_REQUEST';
export const GET_COUPONS_TRANSACTIONS_LIST_SUCCESS = 'GET_COUPONS_TRANSACTIONS_LIST_SUCCESS';
export const GET_COUPONS_TRANSACTIONS_LIST_FAILURE = 'GET_COUPONS_TRANSACTIONS_LIST_FAILURE';

export const GET_COUPON_REQUEST = 'GET_COUPON_REQUEST';
export const GET_COUPON_SUCCESS = 'GET_COUPON_SUCCESS';
export const GET_COUPON_FAILURE = 'GET_COUPON_FAILURE';

export const UPDATE_COUPON_REQUEST = 'UPDATE_COUPON_REQUEST';
export const UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS';
export const UPDATE_COUPON_FAILURE = 'UPDATE_COUPON_FAILURE';

export const CREATE_COUPON_REQUEST = 'CREATE_COUPON_REQUEST';
export const CREATE_COUPON_SUCCESS = 'CREATE_BONUS_SUCCESS';
export const CREATE_COUPON_FAILURE = 'CREATE_COUPON_FAILURE';

export const CLEAR_COUPON = 'CLEAR_COUPON';

