import * as promoCodesActions from '../../actions/promoCodesActions/constants'

const initialState = {
    sales: null,
    promoTrx: null,
    isLoadingPromoTrx: true,
    promoCodesGroups: null,
    promoCodes: null,
    isLoadingPromoCodes: true,
    isPromoCodesGroupsLoading: true,
    isProceedPromoCode: false,
    isProceedGroup: false,
    isCancelProceed: false,
    isCloseGroupProceed: false,
    isUpdateGroupProceed: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case promoCodesActions.GET_PROMO_CODES_TRANSACTIONS_REQUEST:
            return {
                ...state,
                isLoadingPromoTrx: true
            };
        case promoCodesActions.GET_PROMO_CODES_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                promoTrx: action.payload,
                isLoadingPromoTrx: false
            };
        case promoCodesActions.GET_PROMO_CODES_TRANSACTIONS_FAILURE:
            return {
                ...state,
                promoTrx: null,
                isLoadingPromoTrx: false
            };
        case promoCodesActions.GET_PROMO_CODES_LIST_REQUEST:
            return {
                ...state,
                isLoadingPromoCodes: true
            };
        case promoCodesActions.GET_PROMO_CODES_LIST_SUCCESS:
            return {
                ...state,
                promoCodes: action.payload,
                isLoadingPromoCodes: false
            };
        case promoCodesActions.GET_PROMO_CODES_LIST_FAILURE:
            return {
                ...state,
                promoCodes: null,
                isLoadingPromoCodes: false
            };
        case promoCodesActions.GET_SALES_REQUEST:
            return {
                ...state,
            };
        case promoCodesActions.GET_SALES_SUCCESS:
            return {
                ...state,
                sales: action.payload
            };
        case promoCodesActions.GET_SALES_FAILURE:
            return {
                ...state,
            };
        case promoCodesActions.GET_PROMO_CODES_GROUPS_REQUEST:
            return {
                ...state,
                isPromoCodesGroupsLoading: true
            };
        case promoCodesActions.GET_PROMO_CODES_GROUPS_SUCCESS:
            return {
                ...state,
                promoCodesGroups: action.payload,
                isPromoCodesGroupsLoading: false
            };
        case promoCodesActions.GET_PROMO_CODES_GROUPS_FAILURE:
            return {
                ...state,
                isPromoCodesGroupsLoading: false
            };
        case promoCodesActions.ADD_PROMO_CODE_REQUEST:
            return {
                ...state,
                isProceedPromoCode: true
            };
        case promoCodesActions.ADD_PROMO_CODE_SUCCESS:
            return {
                ...state,
                isProceedPromoCode: false
            };
        case promoCodesActions.ADD_PROMO_CODE_FAILURE:
            return {
                ...state,
                isProceedPromoCode: false
            };
        case promoCodesActions.CANCEL_PROMO_CODE_REQUEST:
            return {
                ...state,
                isCancelProceed: true
            };
        case promoCodesActions.CANCEL_PROMO_CODE_SUCCESS:
            return {
                ...state,
                isCancelProceed: false
            };
        case promoCodesActions.CANCEL_PROMO_CODE_FAILURE:
            return {
                ...state,
                isCancelProceed: false
            };
        case promoCodesActions.CLOSE_PROMO_GROUP_REQUEST:
            return {
                ...state,
                isCloseGroupProceed: true
            };
        case promoCodesActions.CLOSE_PROMO_GROUP_SUCCESS:
            return {
                ...state,
                isCloseGroupProceed: false
            };
        case promoCodesActions.CLOSE_PROMO_GROUP_FAILURE:
            return {
                ...state,
                isCloseGroupProceed: false
            };
        case promoCodesActions.UPDATE_PROMO_GROUP_REQUEST:
            return {
                ...state,
                isUpdateGroupProceed: true
            };
        case promoCodesActions.UPDATE_PROMO_GROUP_SUCCESS:
            return {
                ...state,
                isUpdateGroupProceed: false
            };
        case promoCodesActions.UPDATE_PROMO_GROUP_FAILURE:
            return {
                ...state,
                isUpdateGroupProceed: false
            };
        case promoCodesActions.ADD_PROMO_CODES_GROUP_REQUEST:
            return {
                ...state,
                isProceedGroup: true
            };
        case promoCodesActions.ADD_PROMO_CODES_GROUP_SUCCESS:
            return {
                ...state,
                isProceedGroup: false
            };
        case promoCodesActions.ADD_PROMO_CODES_GROUP_FAILURE:
            return {
                ...state,
                isProceedGroup: false
            };
        default:
            return state
    }
}