export const USER_SIGN_IN_REQUEST = 'USER_SIGN_IN_REQUEST';
export const USER_SIGN_IN_SUCCESS = 'USER_SIGN_IN_SUCCESS';
export const USER_SIGN_IN_FAILURE = 'USER_SIGN_IN_FAILURE';

export const USER_VERIFY_OTP_REQUEST = 'USER_VERIFY_OTP_REQUEST';
export const USER_VERIFY_OTP_SUCCESS = 'USER_VERIFY_OTP_SUCCESS';
export const USER_VERIFY_OTP_FAILURE = 'USER_VERIFY_OTP_FAILURE';

export const USER_SIGN_UP_INIT_REQUEST = 'USER_SIGN_UP_INIT_REQUEST';
export const USER_SIGN_UP_INIT_SUCCESS = 'USER_SIGN_UP_INIT_SUCCESS';
export const USER_SIGN_UP_INIT_FAILURE = 'USER_SIGN_UP_INIT_FAILURE';

export const USER_SIGN_UP_STEP_ZERO_REQUEST = 'USER_SIGN_UP_STEP_ZERO_REQUEST';
export const USER_SIGN_UP_STEP_ZERO_SUCCESS = 'USER_SIGN_UP_STEP_ZERO_SUCCESS';
export const USER_SIGN_UP_STEP_ZERO_FAILURE = 'USER_SIGN_UP_STEP_ZERO_FAILURE';

export const USER_SIGN_UP_STEP_ONE_REQUEST = 'USER_SIGN_UP_STEP_ONE_REQUEST';
export const USER_SIGN_UP_STEP_ONE_SUCCESS = 'USER_SIGN_UP_STEP_ONE_SUCCESS';
export const USER_SIGN_UP_STEP_ONE_FAILURE = 'USER_SIGN_UP_STEP_ONE_FAILURE';

export const USER_SIGN_UP_STEP_TWO_REQUEST = 'USER_SIGN_UP_STEP_TWO_REQUEST';
export const USER_SIGN_UP_STEP_TWO_SUCCESS = 'USER_SIGN_UP_STEP_TWO_SUCCESS';
export const USER_SIGN_UP_STEP_TWO_FAILURE = 'USER_SIGN_UP_STEP_TWO_FAILURE';

export const USER_SIGN_UP_CONFIRM_REQUEST = 'USER_SIGN_UP_CONFIRM_REQUEST';
export const USER_SIGN_UP_CONFIRM_SUCCESS = 'USER_SIGN_UP_CONFIRM_SUCCESS';
export const USER_SIGN_UP_CONFIRM_FAILURE = 'USER_SIGN_UP_CONFIRM_FAILURE';

export const USER_SEND_RESET_EMAIL_REQUEST = 'USER_SEND_RESET_EMAIL_REQUEST';
export const USER_SEND_RESET_EMAIL_SUCCESS = 'USER_SEND_RESET_EMAIL_SUCCESS';
export const USER_SEND_RESET_EMAIL_FAILURE = 'USER_SEND_RESET_EMAIL_FAILURE';

export const USER_SET_NEW_PASSWORD_REQUEST = 'USER_SET_NEW_PASSWORD_REQUEST';
export const USER_SET_NEW_PASSWORD_SUCCESS = 'USER_SET_NEW_PASSWORD_SUCCESS';
export const USER_SET_NEW_PASSWORD_FAILURE = 'USER_SET_NEW_PASSWORD_FAILURE';

export const USER_SIGN_OUT_REQUEST = 'USER_SIGN_OUT_REQUEST';
export const USER_SIGN_OUT_SUCCESS = 'USER_SIGN_OUT_SUCCESS';
export const USER_SIGN_OUT_FAILURE = 'USER_SIGN_OUT_FAILURE';

export const CHANGE_ADMIN_ROLE_OR_STATUS_REQUEST = 'CHANGE_ADMIN_ROLE_OR_STATUS_REQUEST';
export const CHANGE_ADMIN_ROLE_OR_STATUS_SUCCESS = 'CHANGE_ADMIN_ROLE_OR_STATUS_SUCCESS';
export const CHANGE_ADMIN_ROLE_OR_STATUS_FAILURE = 'CHANGE_ADMIN_ROLE_OR_STATUS_FAILURE';

export const CHANGE_ADMIN_STATUS_REQUEST = 'CHANGE_ADMIN_STATUS_REQUEST';
export const CHANGE_ADMIN_STATUS_SUCCESS = 'CHANGE_ADMIN_STATUS_SUCCESS';
export const CHANGE_ADMIN_STATUS_FAILURE = 'CHANGE_ADMIN_STATUS_FAILURE';

export const DELETE_ADMIN_USER_REQUEST = 'DELETE_ADMIN_USER_REQUEST';
export const DELETE_ADMIN_USER_SUCCESS = 'DELETE_ADMIN_USER_SUCCESS';
export const DELETE_ADMIN_USER_FAILURE = 'DELETE_ADMIN_USER_FAILURE';

export const RESET_STEPS_DATA = 'RESET_STEPS_DATA';

export const USER_UPDATE_TOKEN = 'USER_UPDATE_TOKEN';
export const USER_REFRESH_TOKENS = 'USER_REFRESH_TOKENS';
