export const GET_PAYMENTS_REQUEST = 'GET_PAYMENTS_REQUEST';
export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS';
export const GET_PAYMENTS_FAILURE = 'GET_PAYMENTS_FAILURE';

export const GET_RATES_REQUEST = 'GET_RATES_REQUEST';
export const GET_RATES_SUCCESS = 'GET_RATES_SUCCESS';
export const GET_RATES_FAILURE = 'GET_RATES_FAILURE';

export const CREATE_NEW_PAYMENT_REQUEST = 'CREATE_NEW_PAYMENT_REQUEST';
export const CREATE_NEW_PAYMENT_SUCCESS = 'CREATE_NEW_PAYMENT_SUCCESS';
export const CREATE_NEW_PAYMENT_FAILURE = 'CREATE_NEW_PAYMENT_FAILURE';

export const UPDATE_PAYMENT_STATUS_REQUEST = 'UPDATE_PAYMENT_STATUS_REQUEST';
export const UPDATE_PAYMENT_STATUS_SUCCESS = 'UPDATE_PAYMENT_STATUS_SUCCESS';
export const UPDATE_PAYMENT_STATUS_FAILURE = 'UPDATE_PAYMENT_STATUS_FAILURE';

export const UPDATE_PAYMENT_DATA_REQUEST = 'UPDATE_PAYMENT_DATA_REQUEST';
export const UPDATE_PAYMENT_DATA_SUCCESS = 'UPDATE_PAYMENT_DATA_SUCCESS';
export const UPDATE_PAYMENT_DATA_FAILURE = 'UPDATE_PAYMENT_DATA_FAILURE';

export const GET_INVOICES_TO_CREATE_PAYMENT_REQUEST = 'GET_INVOICES_TO_CREATE_PAYMENT_REQUEST';
export const GET_INVOICES_TO_CREATE_PAYMENT_SUCCESS = 'GET_INVOICES_TO_CREATE_PAYMENT_SUCCESS';
export const GET_INVOICES_TO_CREATE_PAYMENT_FAILURE = 'GET_INVOICES_TO_CREATE_PAYMENT_FAILURE';

export const GET_CURRENT_INVOICE_FOR_CREATE_PAYMENT_REQUEST = 'GET_CURRENT_INVOICE_FOR_CREATE_PAYMENT_REQUEST';
export const GET_CURRENT_INVOICE_FOR_CREATE_PAYMENT_SUCCESS = 'GET_CURRENT_INVOICE_FOR_CREATE_PAYMENT_SUCCESS';
export const GET_CURRENT_INVOICE_FOR_CREATE_PAYMENT_FAILURE = 'GET_CURRENT_INVOICE_FOR_CREATE_PAYMENT_FAILURE';

export const ERASE_INVOICES = 'ERASE_INVOICES';

export const GET_PAYMENTS_STATISTIC_REQUEST = 'GET_PAYMENTS_STATISTIC_REQUEST';
export const GET_PAYMENTS_STATISTIC_SUCCESS = 'GET_PAYMENTS_STATISTIC_SUCCESS';
export const GET_PAYMENTS_STATISTIC_FAILURE = 'GET_PAYMENTS_STATISTIC_FAILURE';

export const BULK_PAYMENTS_UPDATE_REQUEST = 'BULK_PAYMENTS_UPDATE_REQUEST';
export const BULK_PAYMENTS_UPDATE_SUCCESS = 'BULK_PAYMENTS_UPDATE_SUCCESS';
export const BULK_PAYMENTS_UPDATE_FAILURE = 'BULK_PAYMENTS_UPDATE_FAILURE';