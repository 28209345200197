import * as bonusesActions from '../../actions/bonusesActions/constants'

const initialState = {
    bonusesList: null,
    bonus: null,
    isLoading: true,
    isBonusEditing: false,
    bonusesTransactions: null,
    isTrxLoading: true,
    processedDelete: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case bonusesActions.DELETE_BONUS_TRANSACTION_REQUEST:
            return {
                ...state,
                processedDelete: true,
            };
        case bonusesActions.DELETE_BONUS_TRANSACTION_SUCCESS:
            return {
                ...state,
                processedDelete: false,
            };
        case bonusesActions.DELETE_BONUS_TRANSACTION_FAILURE:
            return {
                ...state,
                processedDelete: false,
            };
        case bonusesActions.GET_BONUSES_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case bonusesActions.GET_BONUSES_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                bonusesList: action.payload,
            };
        case bonusesActions.GET_BONUSES_LIST_FAILURE:
            return {
                ...state,
                bonusesList: null,
                isLoading: false,
            };
        case bonusesActions.GET_BONUSES_TRANSACTIONS_LIST_REQUEST:
            return {
                ...state,
                isTrxLoading: true,
            };
        case bonusesActions.GET_BONUSES_TRANSACTIONS_LIST_SUCCESS:
            return {
                ...state,
                isTrxLoading: false,
                bonusesTransactions: action.payload,
            };
        case bonusesActions.GET_BONUSES_TRANSACTIONS_LIST_FAILURE:
            return {
                ...state,
                bonusesTransactions: null,
                isTrxLoading: false,
            };
        case bonusesActions.GET_BONUS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case bonusesActions.GET_BONUS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                bonus: action.payload,
            };
        case bonusesActions.GET_BONUS_FAILURE:
            return {
                ...state,
                bonus: null,
                isLoading: false,
            };
        case bonusesActions.UPDATE_BONUS_REQUEST:
            return {
                ...state,
                isBonusEditing: true,
            };
        case bonusesActions.UPDATE_BONUS_SUCCESS:
            return {
                ...state,
                isBonusEditing: false,
                bonus: action.payload,
            };
        case bonusesActions.UPDATE_BONUS_FAILURE:
            return {
                ...state,
                isBonusEditing: false,
            };
        case bonusesActions.CREATE_BONUS_REQUEST:
            return {
                ...state,
                isBonusEditing: true,
            };
        case bonusesActions.CREATE_BONUS_SUCCESS:
            return {
                ...state,
                isBonusEditing: false
            };
        case bonusesActions.CREATE_BONUS_FAILURE:
            return {
                ...state,
                isBonusEditing: false,
            };
        case bonusesActions.CLEAR_BONUS:
            return {
                ...state,
                isBonusEditing: false,
                bonus: null
            };
        default:
            return state
    }
}