export const GET_AIRDROP_LIST_REQUEST = 'GET_AIRDROP_LIST_REQUEST';
export const GET_AIRDROP_LIST_SUCCESS = 'GET_AIRDROP_LIST_SUCCESS';
export const GET_AIRDROP_LIST_FAILURE = 'GET_AIRDROP_LIST_FAILURE';

export const CREATE_AIRDROP_REQUEST = 'CREATE_AIRDROP_REQUEST';
export const CREATE_AIRDROP_SUCCESS = 'CREATE_AIRDROP_SUCCESS';
export const CREATE_AIRDROP_FAILURE = 'CREATE_AIRDROP_FAILURE';

export const UPDATE_AIRDROP_REQUEST = 'UPDATE_AIRDROP_REQUEST';
export const UPDATE_AIRDROP_SUCCESS = 'UPDATE_AIRDROP_SUCCESS';
export const UPDATE_AIRDROP_FAILURE = 'UPDATE_AIRDROP_FAILURE';

export const UPDATE_AIRDROP_ORDER_REQUEST = 'UPDATE_AIRDROP_ORDER_REQUEST';
export const UPDATE_AIRDROP_ORDER_SUCCESS = 'UPDATE_AIRDROP_ORDER_SUCCESS';
export const UPDATE_AIRDROP_ORDER_FAILURE = 'UPDATE_AIRDROP_ORDER_FAILURE';

export const GET_STAGES_REQUEST = 'GET_STAGES_REQUEST';
export const GET_STAGES_SUCCESS = 'GET_STAGES_SUCCESS';
export const GET_STAGES_FAILURE = 'GET_STAGES_FAILURE';

export const GET_AIRDROP_ORDERS_REQUEST = 'GET_AIRDROP_ORDERS_REQUEST';
export const GET_AIRDROP_ORDERS_SUCCESS = 'GET_AIRDROP_ORDERS_SUCCESS';
export const GET_AIRDROP_ORDERS_FAILURE = 'GET_AIRDROP_ORDERS_FAILURE';

export const DOWNLOAD_CSV_REQUEST = 'DOWNLOAD_CSV_REQUEST';
export const DOWNLOAD_CSV_SUCCESS = 'DOWNLOAD_CSV_SUCCESS';
export const DOWNLOAD_CSV_FAILURE = 'DOWNLOAD_CSV_FAILURE';

export const ADD_HASH_REQUEST = 'ADD_HASH_REQUEST';
export const ADD_HASH_SUCCESS = 'ADD_HASH_SUCCESS';
export const ADD_HASH_FAILURE = 'ADD_HASH_FAILURE';

export const MATCH_AIRDROP_TRX_REQUEST = 'MATCH_AIRDROP_TRX_REQUEST';
export const MATCH_AIRDROP_TRX_SUCCESS = 'MATCH_AIRDROP_TRX_SUCCESS';
export const MATCH_AIRDROP_TRX_FAILURE = 'MATCH_AIRDROP_TRX_FAILURE';

export const GET_ORDERS_BY_AIRDROP_REQUEST = 'GET_ORDERS_BY_AIRDROP_REQUEST';
export const GET_ORDERS_BY_AIRDROP_SUCCESS = 'GET_ORDERS_BY_AIRDROP_SUCCESS';
export const GET_ORDERS_BY_AIRDROP_FAILURE = 'GET_ORDERS_BY_AIRDROP_FAILURE';

export const CLEAR_AIRDROP_ORDERS = 'CLEAR_AIRDROP_ORDERS';
