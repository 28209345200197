import * as affiliatesActions from '../../actions/affiliatesActions/constants'

const initialState = {
    referrals: null,
    isLoading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case affiliatesActions.GET_AFFILIATES_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case affiliatesActions.GET_AFFILIATES_SUCCESS:
            return {
                ...state,
                affiliates: action.payload,
                isLoading: false
            };
        case affiliatesActions.GET_AFFILIATES_FAILURE:
            return {
                ...state,
                isLoading: false,
                referrals: null
            };
        default:
            return state
    }
}