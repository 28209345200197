import * as stakingProductsActions from '../../actions/stakingActions/products/constants'
import * as stakingDashboardActions from '../../actions/stakingActions/dashboard/constants'

const initialState = {
    products: {
        products: null,
        product: null,
        isLoading: true,
        isProductProcessed: false
    },
    deposits: {
        deposits: null,
        deposit: null,
        isLoading: true,
        depositsRequests: null,
        isLoadingDepositsRequests: true,
        isProceedDepositRequest: false
    },
    transactions: {
        transactions: null,
        isLoading: true
    },
    wallets: {
        isWalletsLoading: true,
        wallets: null
    },
    dashboard: {
        data: null,
        chart: null,
        isDashboardDataLoading: true,
        isChartDataFeedLoading: true,
        totalDepsByProduct: null,
        isTotalDepsLoading: true,
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case stakingDashboardActions.SET_DEFAULTS:
            return {
                ...state,
                dashboard: {
                    data: null,
                    chart: null,
                    isDashboardDataLoading: true,
                    isChartDataFeedLoading: true,
                }
            };
        case stakingDashboardActions.GET_STAKING_DASHBOARD_DATA_REQUEST:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    isDashboardDataLoading: true
                }
            };
        case stakingDashboardActions.GET_STAKING_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    data: action.payload,
                    isDashboardDataLoading: false
                }
            };
        case stakingDashboardActions.GET_STAKING_DASHBOARD_DATA_FAILURE:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    data: null,
                    isDashboardDataLoading: false
                }
            };
        case stakingDashboardActions.GET_STAKING_DASHBOARD_CHART_DATA_REQUEST:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    isChartDataFeedLoading: true
                }
            };
        case stakingDashboardActions.GET_STAKING_DASHBOARD_CHART_DATA_SUCCESS:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    chart: action.payload,
                    isChartDataFeedLoading: false
                }
            };
        case stakingDashboardActions.GET_STAKING_DASHBOARD_CHART_DATA_FAILURE:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    chart: null,
                    isChartDataFeedLoading: false
                }
            };
        case stakingDashboardActions.GET_STAKING_BY_PROGRAM_CHART_DATA_REQUEST:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    isTotalDepsLoading: true
                }
            };
        case stakingDashboardActions.GET_STAKING_BY_PROGRAM_CHART_DATA_SUCCESS:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    totalDepsByProduct: action.payload,
                    isTotalDepsLoading: false
                }
            };
        case stakingDashboardActions.GET_STAKING_BY_PROGRAM_CHART_DATA_FAILURE:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    totalDepsByProduct: null,
                    isTotalDepsLoading: false
                }
            };
        case stakingProductsActions.GET_STAKING_PRODUCTS_REQUEST:
            return {
                ...state,
                products: {
                    ...state.products,
                    isLoading: true
                }
            };
        case stakingProductsActions.GET_STAKING_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: {
                    ...state.products,
                    products: action.payload,
                    isLoading: false
                }
            };
        case stakingProductsActions.GET_STAKING_PRODUCTS_FAILURE:
            return {
                ...state,
                products: {
                    ...state.products,
                    products: null,
                    isLoading: false
                }
            };
        case stakingProductsActions.GET_STAKING_WALLETS_REQUEST:
            return {
                ...state,
                wallets: {
                    ...state.wallets,
                    isWalletsLoading: true
                }
            };
        case stakingProductsActions.GET_STAKING_WALLETS_SUCCESS:
            return {
                ...state,
                wallets: {
                    ...state.wallets,
                    wallets: action.payload,
                    isWalletsLoading: false
                }
            };
        case stakingProductsActions.GET_STAKING_WALLETS_FAILURE:
            return {
                ...state,
                wallets: {
                    ...state.wallets,
                    wallets: null,
                    isWalletsLoading: false
                }
            };
        case stakingProductsActions.GET_STAKING_DEPOSITS_REQUEST:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    isLoading: true
                }
            };
        case stakingProductsActions.GET_STAKING_DEPOSITS_SUCCESS:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    deposits: action.payload,
                    isLoading: false
                }
            };
        case stakingProductsActions.GET_STAKING_DEPOSITS_FAILURE:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    deposits: null,
                    isLoading: false
                }
            };
        case stakingProductsActions.GET_STAKING_DEPOSITS_REQUESTS_REQUEST:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    isLoadingDepositsRequests: true
                }
            };
        case stakingProductsActions.GET_STAKING_DEPOSITS_REQUESTS_SUCCESS:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    depositsRequests: action.payload,
                    isLoadingDepositsRequests: false
                }
            };
        case stakingProductsActions.GET_STAKING_DEPOSITS_REQUESTS_FAILURE:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    isProceedDepositRequest: false
                }
            };
        case stakingProductsActions.CONFIRM_DEPOSIT_REQUEST_REQUEST:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    isProceedDepositRequest: true
                }
            };
        case stakingProductsActions.CONFIRM_DEPOSIT_REQUEST_SUCCESS:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    isProceedDepositRequest: false
                }
            };
        case stakingProductsActions.CONFIRM_DEPOSIT_REQUEST_FAILURE:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    depositsRequests: null,
                    isLoadingDepositsRequests: false
                }
            };
        case stakingProductsActions.GET_STAKING_DEPOSIT_REQUEST:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    isLoading: true
                }
            };
        case stakingProductsActions.GET_STAKING_DEPOSIT_SUCCESS:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    deposit: action.payload,
                    isLoading: false
                }
            };
        case stakingProductsActions.GET_STAKING_DEPOSIT_FAILURE:
            return {
                ...state,
                deposits: {
                    ...state.deposits,
                    deposits: null,
                    isLoading: false
                }
            };
        case stakingProductsActions.GET_STAKING_TRANSACTIONS_REQUEST:
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    isLoading: true
                }
            };
        case stakingProductsActions.GET_STAKING_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    transactions: action.payload,
                    isLoading: false
                }
            };
        case stakingProductsActions.GET_STAKING_TRANSACTIONS_FAILURE:
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    transactions: null,
                    isLoading: false
                }
            };
        case stakingProductsActions.GET_STAKING_PRODUCT_REQUEST:
            return {
                ...state,
                products: {
                    ...state.products,
                    isLoading: true
                }
            };
        case stakingProductsActions.GET_STAKING_PRODUCT_SUCCESS:
            return {
                ...state,
                products: {
                    ...state.products,
                    product: action.payload,
                    isLoading: false
                }
            };
        case stakingProductsActions.GET_STAKING_PRODUCT_FAILURE:
            return {
                ...state,
                products: {
                    ...state.products,
                    product: null,
                    isLoading: false
                }
            };
        case stakingProductsActions.CREATE_STAKING_PRODUCT_REQUEST:
            return {
                ...state,
                products: {
                    ...state.products,
                    isLoading: true,
                    isProductProcessed: true
                }
            };
        case stakingProductsActions.CREATE_STAKING_PRODUCT_SUCCESS:
            return {
                ...state,
                products: {
                    ...state.products,
                    product: action.payload,
                    isLoading: false,
                    isProductProcessed: false
                }
            };
        case stakingProductsActions.CREATE_STAKING_PRODUCT_FAILURE:
            return {
                ...state,
                products: {
                    ...state.products,
                    product: null,
                    isLoading: false,
                    isProductProcessed: false
                }
            };
        case stakingProductsActions.UPDATE_STAKING_PRODUCT_REQUEST:
            return {
                ...state,
                products: {
                    ...state.products,
                    isLoading: true,
                    isProductProcessed: true
                }
            };
        case stakingProductsActions.UPDATE_STAKING_PRODUCT_SUCCESS:
            return {
                ...state,
                products: {
                    ...state.products,
                    product: action.payload,
                    isLoading: false,
                    isProductProcessed: false
                }
            };
        case stakingProductsActions.UPDATE_STAKING_PRODUCT_FAILURE:
            return {
                ...state,
                products: {
                    ...state.products,
                    isLoading: false,
                    isProductProcessed: false
                }
            };
        case stakingProductsActions.CHANGE_STAKING_PRODUCT_STATUS_REQUEST:
            return {
                ...state,
                products: {
                    ...state.products,
                    isProductProcessed: true
                }
            };
        case stakingProductsActions.CHANGE_STAKING_PRODUCT_STATUS_SUCCESS:
            return {
                ...state,
                products: {
                    ...state.products,
                    isProductProcessed: false
                }
            };
        case stakingProductsActions.CHANGE_STAKING_PRODUCT_STATUS_FAILURE:
            return {
                ...state,
                products: {
                    ...state.products,
                    isProductProcessed: false
                }
            };
        default:
            return state
    }
}