export const GET_PROMO_CODES_TRANSACTIONS_REQUEST = 'GET_PROMO_CODES_TRANSACTIONS_REQUEST';
export const GET_PROMO_CODES_TRANSACTIONS_SUCCESS = 'GET_PROMO_CODES_TRANSACTIONS_SUCCESS';
export const GET_PROMO_CODES_TRANSACTIONS_FAILURE = 'GET_PROMO_CODES_TRANSACTIONS_FAILURE';

export const GET_PROMO_CODES_LIST_REQUEST = 'GET_PROMO_CODES_LIST_REQUEST';
export const GET_PROMO_CODES_LIST_SUCCESS = 'GET_PROMO_CODES_LIST_SUCCESS';
export const GET_PROMO_CODES_LIST_FAILURE = 'GET_PROMO_CODES_LIST_FAILURE';

export const GET_PROMO_CODES_GROUPS_REQUEST = 'GET_PROMO_CODES_GROUPS_REQUEST';
export const GET_PROMO_CODES_GROUPS_SUCCESS = 'GET_PROMO_CODES_GROUPS_SUCCESS';
export const GET_PROMO_CODES_GROUPS_FAILURE = 'GET_PROMO_CODES_GROUPS_FAILURE';

export const ADD_PROMO_CODES_GROUP_REQUEST = 'ADD_PROMO_CODES_GROUP_REQUEST';
export const ADD_PROMO_CODES_GROUP_SUCCESS = 'ADD_PROMO_CODES_GROUP_SUCCESS';
export const ADD_PROMO_CODES_GROUP_FAILURE = 'ADD_PROMO_CODES_GROUP_FAILURE';

export const ADD_PROMO_CODE_REQUEST = 'ADD_PROMO_CODE_REQUEST';
export const ADD_PROMO_CODE_SUCCESS = 'ADD_PROMO_CODE_SUCCESS';
export const ADD_PROMO_CODE_FAILURE = 'ADD_PROMO_CODE_FAILURE';

export const CANCEL_PROMO_CODE_REQUEST = 'CANCEL_PROMO_CODE_REQUEST';
export const CANCEL_PROMO_CODE_SUCCESS = 'CANCEL_PROMO_CODE_SUCCESS';
export const CANCEL_PROMO_CODE_FAILURE = 'CANCEL_PROMO_CODE_FAILURE';

export const UPDATE_PROMO_GROUP_REQUEST = 'UPDATE_PROMO_GROUP_REQUEST';
export const UPDATE_PROMO_GROUP_SUCCESS = 'UPDATE_PROMO_GROUP_SUCCESS';
export const UPDATE_PROMO_GROUP_FAILURE = 'UPDATE_PROMO_GROUP_FAILURE';

export const CLOSE_PROMO_GROUP_REQUEST = 'CLOSE_PROMO_GROUP_REQUEST';
export const CLOSE_PROMO_GROUP_SUCCESS = 'CLOSE_PROMO_GROUP_SUCCESS';
export const CLOSE_PROMO_GROUP_FAILURE = 'CLOSE_PROMO_GROUP_FAILURE';

export const GET_SALES_REQUEST = 'GET_SALES_REQUEST';
export const GET_SALES_SUCCESS = 'GET_SALES_SUCCESS';
export const GET_SALES_FAILURE = 'GET_SALES_FAILURE';


