import * as dashboardActions from '../../actions/dashboardActions/constants'

const initialState = {
    chartDataFeed: null,
    isChartDataFeedLoading: true,
    soldChartDataFeed: null,
    isSoldChartDataFeedLoading: true,
    raiseChartDataFeed: null,
    isRaiseChartDataFeedLoading: true,
    dashboardData: null,
    isDashboardDataLoading: true,
    totalsByCountriesData: null,
    isTotalsByCountriesDataLoading: true,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case dashboardActions.GET_PRICE_CHART_DATA_REQUEST:
            return {
                ...state,
                isChartDataFeedLoading: true
            };
        case dashboardActions.GET_PRICE_CHART_DATA_SUCCESS:
            return {
                ...state,
                chartDataFeed: action.payload,
                isChartDataFeedLoading: false
            };
        case dashboardActions.GET_PRICE_CHART_DATA_FAILURE:
            return {
                ...state,
                chartDataFeed: null,
                isChartDataFeedLoading: false
            };
        case dashboardActions.GET_TOKENS_SOLD_CHART_DATA_REQUEST:
            return {
                ...state,
                isSoldChartDataFeedLoading: true
            };
        case dashboardActions.GET_TOKENS_SOLD_CHART_DATA_SUCCESS:
            return {
                ...state,
                soldChartDataFeed: action.payload,
                isSoldChartDataFeedLoading: false
            };
        case dashboardActions.GET_TOKENS_SOLD_CHART_DATA_FAILURE:
            return {
                ...state,
                soldChartDataFeed: null,
                isSoldChartDataFeedLoading: false
            };
        case dashboardActions.GET_RAISED_CHART_DATA_REQUEST:
            return {
                ...state,
                isRaiseChartDataFeedLoading: true
            };
        case dashboardActions.GET_RAISED_CHART_DATA_SUCCESS:
            return {
                ...state,
                raiseChartDataFeed: action.payload,
                isRaiseChartDataFeedLoading: false
            };
        case dashboardActions.GET_RAISED_CHART_DATA_FAILURE:
            return {
                ...state,
                raiseChartDataFeed: null,
                isRaiseChartDataFeedLoading: false
            };
        case dashboardActions.GET_DASHBOARD_DATA_REQUEST:
            return {
                ...state,
                isDashboardDataLoading: true
            };
        case dashboardActions.GET_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                dashboardData: action.payload,
                isDashboardDataLoading: false
            };
        case dashboardActions.GET_DASHBOARD_DATA_FAILURE:
            return {
                ...state,
                dashboardData: null,
                isDashboardDataLoading: false
            };
        case dashboardActions.GET_TOTALS_BY_COUNTRIES_REQUEST:
            return {
                ...state,
                isTotalsByCountriesDataLoading: true
            };
        case dashboardActions.GET_TOTALS_BY_COUNTRIES_SUCCESS:
            return {
                ...state,
                totalsByCountriesData: action.payload,
                isTotalsByCountriesDataLoading: false
            };
        case dashboardActions.GET_TOTALS_BY_COUNTRIES_FAILURE:
            return {
                ...state,
                totalsByCountriesData: null,
                isTotalsByCountriesDataLoading: false
            };
        case dashboardActions.SET_DEFAULTS:
            return {
                chartDataFeed: null,
                isChartDataFeedLoading: true,
                soldChartDataFeed: null,
                isSoldChartDataFeedLoading: true,
                raiseChartDataFeed: null,
                isRaiseChartDataFeedLoading: true,
                dashboardData: null,
                isDashboardDataLoading: true,
                totalsByCountriesData: null,
                isTotalsByCountriesDataLoading: true,
            }
        default:
            return state
    }
}