import * as airdropActions from '../../actions/airdropActions/constants'

const initialState = {
    airdropList: null,
    isLoadingList: true,
    stages: null,
    isProceedAirdrop: false,
    isProceedOrder: false,
    isLoadingOrders: true,
    airdropOrders: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case airdropActions.GET_AIRDROP_LIST_REQUEST:
            return {
                ...state,
                isLoadingList: true
            };
        case airdropActions.GET_AIRDROP_LIST_SUCCESS:
            return {
                ...state,
                airdropList: action.payload,
                isLoadingList: false
            };
        case airdropActions.GET_AIRDROP_LIST_FAILURE:
            return {
                ...state,
                airdropList: null,
                isLoadingList: false
            };
        case airdropActions.GET_ORDERS_BY_AIRDROP_REQUEST:
            return {
                ...state,
                isLoadingOrders: true
            };
        case airdropActions.GET_ORDERS_BY_AIRDROP_SUCCESS:
            return {
                ...state,
                airdropOrders: action.payload,
                isLoadingOrders: false
            };
        case airdropActions.GET_ORDERS_BY_AIRDROP_FAILURE:
            return {
                ...state,
                airdropOrders: null,
                isLoadingOrders: false
            };
        case airdropActions.CLEAR_AIRDROP_ORDERS:
            return {
                ...state,
                airdropOrders: null,
                isLoadingOrders: true
            };
        case airdropActions.UPDATE_AIRDROP_ORDER_REQUEST:
            return {
                ...state,
                isProceedOrder: true
            };
        case airdropActions.UPDATE_AIRDROP_ORDER_SUCCESS:
            return {
                ...state,
                isProceedOrder: false
            };
        case airdropActions.UPDATE_AIRDROP_ORDER_FAILURE:
            return {
                ...state,
                isProceedOrder: false
            };
        case airdropActions.GET_AIRDROP_ORDERS_REQUEST:
            return {
                ...state,
                isLoadingOrders: true
            };
        case airdropActions.GET_AIRDROP_ORDERS_SUCCESS:
            return {
                ...state,
                airdropOrders: action.payload,
                isLoadingOrders: false
            };
        case airdropActions.GET_AIRDROP_ORDERS_FAILURE:
            return {
                ...state,
                airdropOrders: null,
                isLoadingOrders: false
            };
        case airdropActions.UPDATE_AIRDROP_REQUEST:
            return {
                ...state,
                isProceedAirdrop: true
            };
        case airdropActions.UPDATE_AIRDROP_SUCCESS:
            return {
                ...state,
                isProceedAirdrop: false
            };
        case airdropActions.UPDATE_AIRDROP_FAILURE:
            return {
                ...state,
                isProceedAirdrop: false
            };
        case airdropActions.MATCH_AIRDROP_TRX_REQUEST:
            return {
                ...state,
                isProceedAirdrop: true
            };
        case airdropActions.MATCH_AIRDROP_TRX_SUCCESS:
            return {
                ...state,
                isProceedAirdrop: false
            };
        case airdropActions.MATCH_AIRDROP_TRX_FAILURE:
            return {
                ...state,
                isProceedAirdrop: false
            };
        case airdropActions.CREATE_AIRDROP_REQUEST:
            return {
                ...state,
                isProceedAirdrop: true
            };
        case airdropActions.CREATE_AIRDROP_SUCCESS:
            return {
                ...state,
                isProceedAirdrop: false
            };
        case airdropActions.CREATE_AIRDROP_FAILURE:
            return {
                ...state,
                isProceedAirdrop: false
            };
        case airdropActions.GET_STAGES_REQUEST:
            return {
                ...state,
            };
        case airdropActions.GET_STAGES_SUCCESS:
            return {
                ...state,
                stages: action.payload,
            };
        case airdropActions.GET_STAGES_FAILURE:
            return {
                ...state,
                stages: null,
            };
        default:
            return state
    }
}