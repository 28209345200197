export const GET_DASHBOARD_DATA_REQUEST = 'GET_DASHBOARD_DATA_REQUEST';
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_FAILURE = 'GET_DASHBOARD_DATA_FAILURE';

export const GET_TOTALS_BY_COUNTRIES_REQUEST = 'GET_TOTALS_BY_COUNTRIES_REQUEST';
export const GET_TOTALS_BY_COUNTRIES_SUCCESS = 'GET_TOTALS_BY_COUNTRIES_SUCCESS';
export const GET_TOTALS_BY_COUNTRIES_FAILURE = 'GET_TOTALS_BY_COUNTRIES_FAILURE';

export const GET_PRICE_CHART_DATA_REQUEST = 'GET_PRICE_CHART_DATA_REQUEST'
export const GET_PRICE_CHART_DATA_SUCCESS = 'GET_PRICE_CHART_DATA_SUCCESS'
export const GET_PRICE_CHART_DATA_FAILURE = 'GET_PRICE_CHART_DATA_FAILURE'

export const GET_TOKENS_SOLD_CHART_DATA_REQUEST = 'GET_TOKENS_SOLD_CHART_DATA_REQUEST'
export const GET_TOKENS_SOLD_CHART_DATA_SUCCESS = 'GET_TOKENS_SOLD_CHART_DATA_SUCCESS'
export const GET_TOKENS_SOLD_CHART_DATA_FAILURE = 'GET_TOKENS_SOLD_CHART_DATA_FAILURE'

export const GET_RAISED_CHART_DATA_REQUEST = 'GET_RAISED_CHART_DATA_REQUEST'
export const GET_RAISED_CHART_DATA_SUCCESS = 'GET_RAISED_CHART_DATA_SUCCESS'
export const GET_RAISED_CHART_DATA_FAILURE = 'GET_RAISED_CHART_DATA_FAILURE'

export const SET_DEFAULTS = 'SET_DEFAULTS'