export const GET_CONTRACT_REQUEST = 'GET_CONTRACT_REQUEST';
export const GET_CONTRACT_SUCCESS = 'GET_CONTRACT_SUCCESS';
export const GET_CONTRACT_FAILURE = 'GET_CONTRACT_FAILURE';

export const CREATE_UPDATE_CONTRACT_REQUEST = 'CREATE_UPDATE_CONTRACT_REQUEST';
export const CREATE_UPDATE_CONTRACT_SUCCESS = 'CREATE_UPDATE_CONTRACT_SUCCESS';
export const CREATE_UPDATE_CONTRACT_FAILURE = 'CREATE_UPDATE_CONTRACT_FAILURE';

export const GET_STAKING_DASHBOARD_DATA_REQUEST = 'GET_STAKING_DASHBOARD_DATA_REQUEST';
export const GET_STAKING_DASHBOARD_DATA_SUCCESS = 'GET_STAKING_DASHBOARD_DATA_SUCCESS';
export const GET_STAKING_DASHBOARD_DATA_FAILURE = 'GET_STAKING_DASHBOARD_DATA_FAILURE';

export const GET_STAKING_DASHBOARD_CHART_DATA_REQUEST = 'GET_STAKING_DASHBOARD_CHART_DATA_REQUEST';
export const GET_STAKING_DASHBOARD_CHART_DATA_SUCCESS = 'GET_STAKING_DASHBOARD_CHART_DATA_SUCCESS';
export const GET_STAKING_DASHBOARD_CHART_DATA_FAILURE = 'GET_STAKING_DASHBOARD_CHART_DATA_FAILURE';

export const GET_STAKING_BY_PROGRAM_CHART_DATA_REQUEST = 'GET_STAKING_BY_PROGRAM_CHART_DATA_REQUEST';
export const GET_STAKING_BY_PROGRAM_CHART_DATA_SUCCESS = 'GET_STAKING_BY_PROGRAM_CHART_DATA_SUCCESS';
export const GET_STAKING_BY_PROGRAM_CHART_DATA_FAILURE = 'GET_STAKING_BY_PROGRAM_CHART_DATA_FAILURE';

export const SET_DEFAULTS = 'SET_STAKING_DASHBOARD_DEFAULTS';
