export const GET_STAKING_PRODUCTS_REQUEST = 'GET_STAKING_PRODUCTS_REQUEST';
export const GET_STAKING_PRODUCTS_SUCCESS = 'GET_STAKING_PRODUCTS_SUCCESS';
export const GET_STAKING_PRODUCTS_FAILURE = 'GET_STAKING_PRODUCTS_FAILURE';

export const GET_STAKING_WALLETS_REQUEST = 'GET_STAKING_WALLETS_REQUEST';
export const GET_STAKING_WALLETS_SUCCESS = 'GET_STAKING_WALLETS_SUCCESS';
export const GET_STAKING_WALLETS_FAILURE = 'GET_STAKING_WALLETS_FAILURE';

export const GET_STAKING_DEPOSITS_REQUEST = 'GET_STAKING_DEPOSITS_REQUEST';
export const GET_STAKING_DEPOSITS_SUCCESS = 'GET_STAKING_DEPOSITS_SUCCESS';
export const GET_STAKING_DEPOSITS_FAILURE = 'GET_STAKING_DEPOSITS_FAILURE';

export const GET_STAKING_DEPOSITS_REQUESTS_REQUEST = 'GET_STAKING_DEPOSITS_REQUESTS_REQUEST';
export const GET_STAKING_DEPOSITS_REQUESTS_SUCCESS = 'GET_STAKING_DEPOSITS_REQUESTS_SUCCESS';
export const GET_STAKING_DEPOSITS_REQUESTS_FAILURE = 'GET_STAKING_DEPOSITS_REQUESTS_FAILURE';

export const GET_STAKING_DEPOSIT_REQUEST = 'GET_STAKING_DEPOSIT_REQUEST';
export const GET_STAKING_DEPOSIT_SUCCESS = 'GET_STAKING_DEPOSIT_SUCCESS';
export const GET_STAKING_DEPOSIT_FAILURE = 'GET_STAKING_DEPOSIT_FAILURE';

export const GET_STAKING_TRANSACTIONS_REQUEST = 'GET_STAKING_TRANSACTIONS_REQUEST';
export const GET_STAKING_TRANSACTIONS_SUCCESS = 'GET_STAKING_TRANSACTIONS_SUCCESS';
export const GET_STAKING_TRANSACTIONS_FAILURE = 'GET_STAKING_TRANSACTIONS_FAILURE';

export const GET_STAKING_PRODUCT_REQUEST = 'GET_STAKING_PRODUCT_REQUEST';
export const GET_STAKING_PRODUCT_SUCCESS = 'GET_STAKING_PRODUCT_SUCCESS';
export const GET_STAKING_PRODUCT_FAILURE = 'GET_STAKING_PRODUCT_FAILURE';

export const CREATE_STAKING_PRODUCT_REQUEST = 'CREATE_STAKING_PRODUCT_REQUEST';
export const CREATE_STAKING_PRODUCT_SUCCESS = 'CREATE_STAKING_PRODUCT_SUCCESS';
export const CREATE_STAKING_PRODUCT_FAILURE = 'CREATE_STAKING_PRODUCT_FAILURE';

export const UPDATE_STAKING_PRODUCT_REQUEST = 'UPDATE_STAKING_PRODUCT_REQUEST';
export const UPDATE_STAKING_PRODUCT_SUCCESS = 'UPDATE_STAKING_PRODUCT_SUCCESS';
export const UPDATE_STAKING_PRODUCT_FAILURE = 'UPDATE_STAKING_PRODUCT_FAILURE';

export const CONFIRM_DEPOSIT_REQUEST_REQUEST = 'CONFIRM_DEPOSIT_REQUEST_REQUEST';
export const CONFIRM_DEPOSIT_REQUEST_SUCCESS = 'CONFIRM_DEPOSIT_REQUEST_SUCCESS';
export const CONFIRM_DEPOSIT_REQUEST_FAILURE = 'CONFIRM_DEPOSIT_REQUEST_FAILURE';

export const CHANGE_STAKING_PRODUCT_STATUS_REQUEST = 'CHANGE_STAKING_PRODUCT_STATUS_REQUEST';
export const CHANGE_STAKING_PRODUCT_STATUS_SUCCESS = 'CHANGE_STAKING_PRODUCT_STATUS_SUCCESS';
export const CHANGE_STAKING_PRODUCT_STATUS_FAILURE = 'CHANGE_STAKING_PRODUCT_STATUS_FAILURE';

export const CHANGE_WALLET_STATUS_REQUEST = 'CHANGE_WALLET_STATUS_REQUEST';
export const CHANGE_WALLET_STATUS_SUCCESS = 'CHANGE_WALLET_STATUS_SUCCESS';
export const CHANGE_WALLET_STATUS_FAILURE = 'CHANGE_WALLET_STATUS_FAILURE';

export const ADD_WALLET_REQUEST = 'ADD_WALLET_REQUEST';
export const ADD_WALLET_SUCCESS = 'ADD_WALLET_SUCCESS';
export const ADD_WALLET_FAILURE = 'ADD_WALLET_FAILURE';
